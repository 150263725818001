import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    experiences: [
      {
        id:6,
        name: "Hornbill Festival",
        location: 'Nagaland',
        duration:"2D 1N",
        tags:["Camping","Festival"],
        // background:"https://firebasestorage.googleapis.com/v0/b/stayhighadventures.appspot.com/o/hornbill%2F13.jpg?alt=media&token=51d7b3ab-a4d2-44ca-b56c-34f1f59d0af0",
        background:"https://storage.googleapis.com/stayhighadventures.appspot.com/hornbill/01.jpg",
        stats: ['1585', '10-20', '1st - 10th Dec, 2024'],
        shots: [
          // "https://firebasestorage.googleapis.com/v0/b/stayhighadventures.appspot.com/o/hornbill%2F7.jpg?alt=media&token=44077844-b210-495e-a80c-a97587fed8b7",
          // "https://firebasestorage.googleapis.com/v0/b/stayhighadventures.appspot.com/o/hornbill%2F8.jpg?alt=media&token=42068475-035a-482d-9013-f57f6943b422",
          // "https://firebasestorage.googleapis.com/v0/b/stayhighadventures.appspot.com/o/hornbill%2F9.JPG?alt=media&token=73b88ac8-968e-4652-8f4f-7576b335aabc",
          // "https://firebasestorage.googleapis.com/v0/b/stayhighadventures.appspot.com/o/hornbill%2F10.jpeg?alt=media&token=bfa54bbf-94a8-403a-a0f5-468d44a51e6f",
          "https://firebasestorage.googleapis.com/v0/b/stayhighadventures.appspot.com/o/hornbill%2F17.JPG?alt=media&token=d9525384-1eb2-4d33-9c8b-73bf624b845e",
          "https://firebasestorage.googleapis.com/v0/b/stayhighadventures.appspot.com/o/hornbill%2F16.JPG?alt=media&token=b9e43341-06af-400a-a222-fd60c5d64274",
          "https://firebasestorage.googleapis.com/v0/b/stayhighadventures.appspot.com/o/hornbill%2F15.JPG?alt=media&token=d683e231-bc71-4c66-bfe1-fbabb6a48616",
          "https://firebasestorage.googleapis.com/v0/b/stayhighadventures.appspot.com/o/hornbill%2F14.JPG?alt=media&token=508eef3a-e622-4eb3-893a-18de5625da89",
          "https://firebasestorage.googleapis.com/v0/b/stayhighadventures.appspot.com/o/hornbill%2F11.jpg?alt=media&token=2640121b-0a77-49f6-a14f-403282b2a6d7",
          "https://firebasestorage.googleapis.com/v0/b/stayhighadventures.appspot.com/o/hornbill%2F12.JPG?alt=media&token=17559752-c5d5-4b59-8980-167e05c6f30c"
        ],
        // about:"The Hornbill Festival is aptly called 'the Festival of Festivals' as it sees participation of all the tribes and sub tribes of Nagaland. The festival draws a huge number of domestic and international tourists every year and is organized in the Naga Heritage Village of Kisama. It aims to revive and protect the rich culture and diverse traditions of the Naga people. The festival includes performances, crafts, games, food fairs and religious ceremonies. Stay High brings you their campsite located 1.5 km away from the Kisama Heritage Village at the base of the mighty Mt. Japfu!",
        about:"The Hornbill Festival is aptly called 'the Festival of Festivals' as it sees participation of all the tribes and sub tribes of Nagaland. The festival draws a huge number of domestic and international tourists every year and is organized in the Naga Heritage Village of Kisama. It aims to revive and protect the rich culture and diverse traditions of the Naga people. The festival includes performances, crafts, games, food fairs and religious ceremonies. Stay High Adventures brings you their campsite located right in the Kisama Heritage Village.",
        itenarary: [
          "3 AC train tickets(Guwahati to Guwahati), Taxi to Kisama (return), Dzukou trek, Village visit and Khonoma Village Tour , camping accomodation (6N), Breakfast (6), Dinner (6).",
          "Dzukou trek, Village visit, camping accommodation (4N), Breakfast (6), Dinner (6).",
          "Camping accommodation (1N), Breakfast (1), Dinner (1)."
        ],
        packageInclusions: [
          { title: "Tents - rainproof & wind proof", icon: 0 },
          { title: "Sleeping mats, bags/blankets", icon: 6 },
          { title: "Electricity", icon: 8 },
          { title: "Bed-sheets", icon: 19 },
          { title: "Bonfire", icon: 9 },
          { title: "Filtered Water", icon: 10 },
          { title: "Camp Kitchen", icon: 11 },
          { title: "First Aid", icon: 12 },
          { title: "Clean and hygienic Toilets", icon: 13 },
          { title: "Battery Operated Lamps", icon: 18 },
          { title: "Parking*", icon: 15 },
        ],
        cost: '1799',
        isEvent: true,
        // dates: [{ day: 1, month: 'Dec' }, { day: 10, month: 'Dec' }]
      },       
      {
        id:7,
        name: "Ziro Festival of Music",
        location: 'Arunachal',
        duration:"5D 4N",
        tags:["Camping","Festival"],
        background:"https://firebasestorage.googleapis.com/v0/b/stayhighadventures.appspot.com/o/ziro%2F1.jpg?alt=media&token=aceb71b6-2108-484a-bfe8-5ee4706c1e70",
        stats: ['1600', '18-20', '26th Sep - 29th Sep, 2024'],
        shots: [
          "https://firebasestorage.googleapis.com/v0/b/stayhighadventures.appspot.com/o/ziro%2F1.jpg?alt=media&token=aceb71b6-2108-484a-bfe8-5ee4706c1e70",
          // "https://firebasestorage.googleapis.com/v0/b/stayhighadventures.appspot.com/o/ziro%2F2.jpg?alt=media&token=cc263509-2762-46a1-aea7-0a87c3af7d0b",
          // "https://firebasestorage.googleapis.com/v0/b/stayhighadventures.appspot.com/o/ziro%2F7.jpeg?alt=media&token=b1aa12b1-bf86-4daf-b27c-d2ffe38995e6&_gl=1*72tj05*_ga*MTUyMDMyMTEzNC4xNjgzODE2MjY0*_ga_CW55HF8NVT*MTY4NjExODk1OC4yMi4xLjE2ODYxMjExNTUuMC4wLjA.",
          // "https://firebasestorage.googleapis.com/v0/b/stayhighadventures.appspot.com/o/ziro%2F4.jpg?alt=media&token=b1e89799-6aac-442a-8d00-b9c1ebe4fb91",
          "https://firebasestorage.googleapis.com/v0/b/stayhighadventures.appspot.com/o/ziro%2F8.jpeg?alt=media&token=c5564cd5-5795-4c57-a025-2f6749a15ba0&_gl=1*hfb25p*_ga*MTUyMDMyMTEzNC4xNjgzODE2MjY0*_ga_CW55HF8NVT*MTY4NjExODk1OC4yMi4xLjE2ODYxMjEyMzUuMC4wLjA.",
          "https://firebasestorage.googleapis.com/v0/b/stayhighadventures.appspot.com/o/ziro%2F6.jpg?alt=media&token=a3998b5b-20ee-43f0-8390-0099ba9c5c8a",
          "https://firebasestorage.googleapis.com/v0/b/stayhighadventures.appspot.com/o/ziro%2F15.JPG?alt=media&token=c88e4234-c3d8-433f-85c6-e78766e93338",
          "https://firebasestorage.googleapis.com/v0/b/stayhighadventures.appspot.com/o/ziro%2F12.JPG?alt=media&token=bb4f87a0-4d43-4768-80b3-0f6981d4aa74",
          "https://firebasestorage.googleapis.com/v0/b/stayhighadventures.appspot.com/o/ziro%2F14.JPG?alt=media&token=494d4d1b-98e1-42e3-99d8-e921ac4ce846"
        ],
        // about:"The Hornbill Festival is aptly called 'the Festival of Festivals' as it sees participation of all the tribes and sub tribes of Nagaland. The festival draws a huge number of domestic and international tourists every year and is organized in the Naga Heritage Village of Kisama. It aims to revive and protect the rich culture and diverse traditions of the Naga people. The festival includes performances, crafts, games, food fairs and religious ceremonies. Stay High brings you their campsite located 1.5 km away from the Kisama Heritage Village at the base of the mighty Mt. Japfu!",
        about:"Welcome to India’s Largest Outdoor Music Festival!. Set among golden paddy fields and rolling mountains, Ziro is home to the Apatani Tribe and proposed by UNESCO as a World Heritage Site. The tribe is known for its vibrant festivals, intricate handloom designs, skills in cane and bamboo crafts and peaceful co-existence with nature. StayHigh Adventures brings to you its campsite at just 5 minutes from Ziro Festival grounds. Witness this musical extravaganza as a local while exploring the town trying everything from its famous rice beer (Apong) to even fish farming.",
        itenarary: [
          "3 AC Train tickets from Guwahati (Return),Taxi transfer from Itanagar to Ziro (Return), Inner Line Permit, Accommodation in tents (Twin sharing), Dinner and Breakfast, Concrete Toilets, Evening jamming/Acoustic music, Village Visit/Walk, Traditional Dance Performance",
          "Accommodation in tents (Twin sharing), Dinner and Breakfast, Concrete Toilets, Evening jamming/Acoustic music, Traditional Dance Performance.",
        ],
        packageInclusions: [
          { title: "Tents - rainproof & wind proof", icon: 0 },
          { title: "Sleeping mats, bags/blankets", icon: 6 },
          { title: "Electricity", icon: 8 },
          { title: "Bed-sheets", icon: 19 },
          { title: "Bonfire", icon: 9 },
          { title: "Filtered Water", icon: 10 },
          { title: "Camp Kitchen", icon: 11 },
          { title: "First Aid", icon: 12 },
          { title: "Clean and hygienic Toilets", icon: 13 },
          { title: "Battery Operated Lamps", icon: 18 },
          { title: "Parking*", icon: 15 },
        ],
        cost: '10999',
        isEvent: true,
        // dates: [{ day: 1, month: 'Dec' }, { day: 10, month: 'Dec' }]
      },                 
      {
        id:1,
        name: "The Great Dzukou Escape",
        location: 'Nagaland',
        duration:"3D 2N",
        tags:["Camping","Trekking"],
        background:"https://firebasestorage.googleapis.com/v0/b/stayhighadventures.appspot.com/o/dzukou%2FDSC_8496.jpg?alt=media&token=1e0b8b24-c79c-488e-bcb2-4be4557ffd50",
        stats: ['2452', '2 - 15', 'Hard'],
        shots: [
          "https://firebasestorage.googleapis.com/v0/b/stayhighadventures.appspot.com/o/dzukou%2FDSC_8496.jpg?alt=media&token=1e0b8b24-c79c-488e-bcb2-4be4557ffd50",
          // "https://firebasestorage.googleapis.com/v0/b/stayhighadventures.appspot.com/o/dzukou%2FDSC_8501.jpg?alt=media&token=e5799d37-4b09-4d9d-adf5-a7d20e4597c7",
          "https://firebasestorage.googleapis.com/v0/b/stayhighadventures.appspot.com/o/dzukou%2FDSC_8502.jpg?alt=media&token=26c16386-a12f-426a-bb28-11789b342f2e",
          "https://firebasestorage.googleapis.com/v0/b/stayhighadventures.appspot.com/o/dzukou%2FDSC_8520.jpg?alt=media&token=c9802031-655c-4cb1-a13c-5cc7e2005439",
          "https://firebasestorage.googleapis.com/v0/b/stayhighadventures.appspot.com/o/dzukou%2FDSC_8529.jpg?alt=media&token=e0ec73f5-e5cb-4802-8cdb-3feb4a3450ac",
          "https://firebasestorage.googleapis.com/v0/b/stayhighadventures.appspot.com/o/dzukou%2FDSC_8551.jpg?alt=media&token=db808c35-17e5-459e-95e3-0ebc956555c2",
          "https://firebasestorage.googleapis.com/v0/b/stayhighadventures.appspot.com/o/dzukou%2FDSC_8579.jpg?alt=media&token=2445e6d2-5fbf-44bb-8b98-ac5633191f5f"
        ],
        about:" Dzu-kou means cold waters in the local Angami dialect of Nagaland. Fondly referred to as the 'Canvas of the Gods', the valley is full of bamboo thickets that cover the hills, making it a sight to see. Vast green grasslands, narrow trails, crystal clear waters, caves, starry skies, bamboo thickets, different species of birds is bound to take your breath away and leave you mesmerized for days to follow.",
        itenarary: [
          "Check in to campsite/homestay and acclimatise for the trek",
          "After breakfast move out for the trek, uphill climb for 3.5 hrs. then pitch camp.",
          "Explore the caves and by 11 AM start on the downhill trek. Reach campsite/homestay and check out."
        ],
        packageInclusions: [
          { title: "Accommodation in tents / homestay", icon: 0 },
          { title: "All meals", icon: 1 },
          { title: "Transport to Trek start point", icon: 2 },
          { title: "Dzukou entry fees", icon: 3 },
          { title: "Guide & Porter charges", icon: 4 },
          { title: "Inner Line Permit", icon: 5 }
        ],
        cost: '4999'
      },
           
      {
        id:2,
        name: "Heartland Assam",
        location: 'Jorhat',
        duration:"2D 1N",
        tags:["Camping","Trekking"],
        background:"https://firebasestorage.googleapis.com/v0/b/stayhighadventures.appspot.com/o/heartland%2FDSC_1285.jpg?alt=media&token=88812de5-f096-483a-9517-2125192ab1ed",
        stats: ['116', '15 - 35', 'Easy'],
        shots: [
          "https://firebasestorage.googleapis.com/v0/b/stayhighadventures.appspot.com/o/heartland%2FDSC_0817.jpg?alt=media&token=9bcf21c8-f1ec-44d4-b554-365063c539c8",
          "https://firebasestorage.googleapis.com/v0/b/stayhighadventures.appspot.com/o/heartland%2FDSC_0833.jpg?alt=media&token=4966f534-0896-4b44-a682-6427ebf3ceff",
          "https://firebasestorage.googleapis.com/v0/b/stayhighadventures.appspot.com/o/heartland%2FHeartland%20Gallery%20replacement%20with%20bullock%20cart%20photo.jpg?alt=media&token=c473eb7b-3e4f-415e-b56c-c802193c872f",
          // "https://firebasestorage.googleapis.com/v0/b/stayhighadventures.appspot.com/o/heartland%2FDSC_1011.jpg?alt=media&token=d672a97e-fff8-4c70-979c-a68b392715d7",
          "https://firebasestorage.googleapis.com/v0/b/stayhighadventures.appspot.com/o/heartland%2FDSC_1077.jpg?alt=media&token=af2cbe0a-ef57-42fe-872a-b7b8acba83ff",
          // "https://firebasestorage.googleapis.com/v0/b/stayhighadventures.appspot.com/o/heartland%2FDSC_1285.jpg?alt=media&token=88812de5-f096-483a-9517-2125192ab1ed",
          "https://firebasestorage.googleapis.com/v0/b/stayhighadventures.appspot.com/o/heartland%2FHeartland%20Gallery%20replacement-min%20(1).jpg?alt=media&token=e57609f3-a105-4e74-8bf4-d409009c75a0",
          "https://firebasestorage.googleapis.com/v0/b/stayhighadventures.appspot.com/o/heartland%2FIMG_8107.jpg?alt=media&token=f264cd74-7832-44fa-b935-dd2a9423672e"
        ],
        about:"Heartland Assam is an exhilarating journey covering Majuli and Molai Forest. Molai forest is the only man made forest in India. The forest was created by Jadav Payeng, who has since been given the name - Forest Man of India and has won many prestigious awards including the Padma Bhushan. Also explore Majuli, the largest riverine island in the world.",
        itenarary: [
          "Reach Jorhat and enjoy the drive to the campsite (20 mins/8 kms). Check into Mohian Cove.",
          "Enjoy bonfire and some old fashioned music by the riverside. Post dinner retire for an early day",
          "Start early after breakfast and cross the river by motorboat. Enjoy a jungle trek catching glimpses of wildlife and enjoying ethnic food",
        ],
        packageInclusions: [
          { title: "Accommodation in tents", icon: 0 },
          { title: "Sleeping bags", icon: 6 },
          { title: "Electricity", icon: 8 },
          { title: "River crossing (boat/ferry)", icon: 14 },
          { title: "Bonfire", icon: 9 },
          { title: "Filtered Water", icon: 10 },
          { title: "Camp Kitchen", icon: 11 },
          { title: "First Aid", icon: 12 },
          { title: "Pick & Drop (available on request)", icon: 2 },
          { title: "Guide Charges", icon: 4 },
          { title: "Parking", icon: 15 },
        ],
        cost: '1999'        
      },  
      {
        id:3,
        name: "Leap of Likai",
        location: 'Meghalaya',
        duration:"2D 1N",
        tags:["Camping","Trekking","Waterfall"],
        background:"https://firebasestorage.googleapis.com/v0/b/stayhighadventures.appspot.com/o/likai%2FIMG_8693.jpg?alt=media&token=f6954401-062c-4083-bd85-dbb3e6b790bd",
        stats: ['1430', '10 - 21', 'Medium'],
        shots: [
          "https://firebasestorage.googleapis.com/v0/b/stayhighadventures.appspot.com/o/likai%2FIMG_8693.jpg?alt=media&token=f6954401-062c-4083-bd85-dbb3e6b790bd",
          "https://firebasestorage.googleapis.com/v0/b/stayhighadventures.appspot.com/o/likai%2FIMG_8767.jpg?alt=media&token=116954a7-2551-4643-afda-a3cc8ff5e872",
          "https://firebasestorage.googleapis.com/v0/b/stayhighadventures.appspot.com/o/likai%2FIMG_8768.jpg?alt=media&token=bbf820ba-b493-4fc6-a8e9-2f6cd43333cd",
          "https://firebasestorage.googleapis.com/v0/b/stayhighadventures.appspot.com/o/likai%2FIMG_8798.jpg?alt=media&token=c89c3d30-505d-4396-acba-ec7ade227b6f",
          "https://firebasestorage.googleapis.com/v0/b/stayhighadventures.appspot.com/o/likai%2FIMG_8819%20(1).jpg?alt=media&token=0814b3b5-cac6-4956-9550-a240873c86b9",
          "https://firebasestorage.googleapis.com/v0/b/stayhighadventures.appspot.com/o/likai%2FIMG_8835.jpg?alt=media&token=b836a6da-7ad7-47d9-a7d8-da79c28dbbdc"
        ],
        about:"Noh-Ka-Likai falls is located in Meghalaya and is the tallest plunge waterfall in india. Towering at 1115 metres, it is very close to Cherrapunji, the wettest place on Earth. Due to availability of rainwater the waterfall stays in force all through the year. The plunge pool of the waterfall is also fascinating as it is bluish green in colour.",
        itenarary: [
          "Pickup from Khanapara at 6:30 AM, breakfast on the way and reach Nohkalikai campsite at 11 AM. Check in, enjoy some refreshments and start the trek",
          "After a 3.5 hour trek, reach the campsite and enjoy ethnic lunch and relax. In the evening visit the viewpoint and walk to the local bazaar if time permits",
          "Enjoy evening bonfire and music and then post dinner retire for the night"
        ],
        packageInclusions: [
          { title: "Tents - rainproof & wind proof", icon: 0 },
          { title: "Sleeping mats", icon: 6 },
          { title: "Blankets / sleeping bags", icon: 7 },
          { title: "Electricity (only at base camp)", icon: 8 },
          { title: "Clean and hygienic Toilets", icon: 13 },
          { title: "Bonfire", icon: 9 },
          { title: "Water", icon: 10 },
          { title: "Camp Kitchen", icon: 11 },
          { title: "First Aid ", icon: 12 },
          { title: "Pick and drop from Guwahati", icon: 2 },
        ],
        cost: '3999'
      },     
      {
        id:4,
        name: "Mohian Cove",
        location: 'Assam',
        duration:"2D 1N",
        tags:["Camping","Trekking"],
        background:"https://firebasestorage.googleapis.com/v0/b/stayhighadventures.appspot.com/o/mohian%2FIMG_8183.jpg?alt=media&token=7073f73d-49a0-4c4d-8a3f-907598d1d690",
        stats: ['116', '15-35', 'Easy'],
        shots: [
          "https://firebasestorage.googleapis.com/v0/b/stayhighadventures.appspot.com/o/mohian%2FMohian%20Cove%20replacement.jpg?alt=media&token=2994a695-9d28-45a7-b87c-d4d2bf244697",
          "https://firebasestorage.googleapis.com/v0/b/stayhighadventures.appspot.com/o/mohian%2FDSC_0954.jpg?alt=media&token=835510cd-f300-4bce-b9da-a7bd0a6e2f5b",
          // "https://firebasestorage.googleapis.com/v0/b/stayhighadventures.appspot.com/o/mohian%2FDSC_1445.jpg?alt=media&token=3c2eaf68-995c-422d-b310-a50b56d3fc07",
          "https://firebasestorage.googleapis.com/v0/b/stayhighadventures.appspot.com/o/heartland%2FMohian%20Cove%20replacement%20(1).jpg?alt=media&token=f5677410-ec36-4815-a0bf-68bd6efa98f2",
          "https://firebasestorage.googleapis.com/v0/b/stayhighadventures.appspot.com/o/mohian%2FDSC_1490.jpg?alt=media&token=d7547218-3dcb-4d86-a106-c6ce7ff19cb0",
          "https://firebasestorage.googleapis.com/v0/b/stayhighadventures.appspot.com/o/mohian%2FDSC_1769.jpg?alt=media&token=ebe6ffde-26c5-4927-895b-dd7daacdeea7",
          "https://firebasestorage.googleapis.com/v0/b/stayhighadventures.appspot.com/o/mohian%2FIMG_8183.jpg?alt=media&token=7073f73d-49a0-4c4d-8a3f-907598d1d690",
// "https://firebasestorage.googleapis.com/v0/b/stayhighadventures.appspot.com/o/mohian%2FIMG_8407.jpg?alt=media&token=18999beb-ea36-4c5c-86b3-71294848988a"
        ],
        about:"Mohian Cove is an eco campground on the banks of the Brahmaputra river. It is inspired by the culture of people living in a typical Assamese fishing village. Built with extensive use of bamboo, Mohian Cove also has a 'sang-ghor'(a traditional house made on bamboo stilts) for Guests to sit and relax by the riverside. Mohian Cove offers expansive views of the Brahmaputra river and is close to Majuli (the largest riverine island in the world).",
        itenarary: [
          "Reach Jorhat and enjoy the drive to the campsite (20 mins/8 kms). Check into the riverside campsite and enjoy an evening beach walk. Bonfire and dinner in ethnic style.",
          "Next day catch the sunrise and enjoy a walk along with bird watching. Post breakfast, you can go for a quick village walk along the river bank."
        ],
        packageInclusions: [
          { title: "Accommodation in tents", icon: 0 },
          { title: "Sleeping bags", icon: 7 },
          { title: "Electricity", icon: 8 },
          { title: "Clean and hygienic Toilets", icon: 13 },
          { title: "Bonfire", icon: 9 },
          { title: "Birding", icon: 16 },
          { title: "Filtered Water", icon: 10 },
          { title: "Camp Kitchen", icon: 11 },
          { title: "Pick & Drop(available on request)", icon: 2 },
          { title: "Parking ", icon: 15 },
        ],
        cost: '990'        
      },
      {
        id:5,
        name: "Camp Tusker Trail",
        location: 'Assam',
        duration:"2D 1N",
        tags:["Camping","Trekking"],
        background:"https://firebasestorage.googleapis.com/v0/b/stayhighadventures.appspot.com/o/tusker%2Fdsc_2482.jpg?alt=media&token=7d83d610-766c-4f4a-b5b5-dc0f756c79e2",
        stats: ['330', '12-35', 'Easy'],
        shots: [
          "https://firebasestorage.googleapis.com/v0/b/stayhighadventures.appspot.com/o/tusker%2FDSC_2161.jpg?alt=media&token=5e14c502-0359-4257-8147-edd36bab7346",
          "https://firebasestorage.googleapis.com/v0/b/stayhighadventures.appspot.com/o/tusker%2FDSC_2186.jpg?alt=media&token=1c3dd66c-cb23-4eca-a2d8-981541d1495a",
          "https://firebasestorage.googleapis.com/v0/b/stayhighadventures.appspot.com/o/tusker%2FDSC_2188.jpg?alt=media&token=6317f915-d688-4b6a-a4b2-92d1c49878d3",
          "https://firebasestorage.googleapis.com/v0/b/stayhighadventures.appspot.com/o/tusker%2FDSC_2213.jpg?alt=media&token=9815a7a8-087b-48b8-80a4-108ab1b7bb8d",
          "https://firebasestorage.googleapis.com/v0/b/stayhighadventures.appspot.com/o/tusker%2FDSC_2468.jpg?alt=media&token=ef655c76-9ef3-4ce4-89e9-e69e1630fe87",
          "https://firebasestorage.googleapis.com/v0/b/stayhighadventures.appspot.com/o/tusker%2FDSC_2539.jpg?alt=media&token=e47244b4-25ec-4079-bd85-0f2304a28a1d",
          // "https://firebasestorage.googleapis.com/v0/b/stayhighadventures.appspot.com/o/mohian%2FIMG_8407.jpg?alt=media&token=18999beb-ea36-4c5c-86b3-71294848988a"
        ],
        about:"Camp Tusker trail is located 30 kms. away from Guwahati, deep in the forests, surrounded by hills on all sides. Set in the wilderness with rural touches, Tusker Trail is a sustainable eco campground running on renewable energy. Enjoy a day out with ethnic lunch and a trek to the waterfall. Taste delicious local cuisines sourced from the village and relax in the campsite.",
        itenarary: [
          "Drive down from Guwahati towards Rani via Deepor Beel road (30 kms. / 1 hour). Reach camp after a drive/ride of 7 kms. Enjoy the waterfall trek and ethnic lunch",
          "Spend the night it tents in the midst of nature, enjoy breakfast early in the morning and go birdwatching."
        ],
        packageInclusions: [
          { title: "Accommodation in tents", icon: 0 },
          { title: "Sleeping bags", icon: 7 },
          { title: "Solar powered lights", icon: 8 },
          { title: "Waterfall trek", icon: 17 },
          { title: "Clean and hygienic Toilets", icon: 13 },
          { title: "Bonfire(winters)", icon: 9 },
          { title: "First Aid", icon: 12 },
          { title: "Filtered Water", icon: 10 },
          { title: "Camp Kitchen", icon: 11 },
          { title: "Pick & Drop(available on request)", icon: 2 },
          { title: "Guide Charges", icon: 4 },
          { title: "Parking ", icon: 15 },
        ],
        cost: '1199'        
      }          
    ]
  },
  getters: {
    experiences(state) {
      return state.experiences
    }
  }, 
  mutations: {
  },
  actions: {
  },
  modules: {
  }
})
